import React from 'react';
import Footer from '../../../components/shared/Footer/Footer';
import Navigation from '../../../components/shared/Navigation/Navigation';
import ServicesDetailsBreadcrumb from './ServicesDetailsBreadcrumb/ServicesDetailsBreadcrumb';
import ServicesDetailsFaq from './ServicesDetailsFaq/ServicesDetailsFaq';
import ContactForm from '../../Contact/Contact/ContactForm/ContactForm';

const ServicesDetails = () => {
    return (
        <>
            <Navigation />
            <ServicesDetailsBreadcrumb />
            <ServicesDetailsFaq />
            <ContactForm/>
            <Footer />
        </>
    );
};

export default ServicesDetails;