// import React, { useState } from 'react';
// import axios from 'axios';

// const ApointArea = () => {
//     const [formData, setFormData] = useState({
//         name: '',
//         contactNumber: '',
//         email: '',
//         service: '',
//         message: ''
//     });

//     const [errors, setErrors] = useState({});

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const validateForm = () => {
//         const errors = {};
//         if (!formData.name.trim()) {
//             errors.name = 'Please enter your name.';
//         }
//         if (!formData.contactNumber.trim()) {
//             errors.contactNumber = 'Please enter your contact number.';
//         } else if (!/^\d+$/.test(formData.contactNumber)) {
//             errors.contactNumber = 'Contact number is invalid.';
//         }
//         if (!formData.email.trim()) {
//             errors.email = 'Please enter your email address.';
//         } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//             errors.email = 'Email address is invalid.';
//         }
//         if (formData.service === '') {
//             errors.service = 'Please choose a service.';
//         }
//         return errors;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const validationErrors = validateForm();
//         if (Object.keys(validationErrors).length > 0) {
//             setErrors(validationErrors);
//             return;
//         }
        
//         try {
//             const apiData = {
//                 full_name: formData.name,
//                 email: formData.email,
//                 mobile_no: formData.contactNumber,
//                 message: formData.message,
//                 services: formData.service,
//             };

//             const response = await axios.post('https://cleaning.smilesdahub.com/api.php', apiData, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//             });

//             console.log('Form submitted successfully', response.data);
//             alert("Form Successfully submit");
            
//             // Reset form
//             setFormData({
//                 name: '',
//                 contactNumber: '',
//                 email: '',
//                 service: '',
//                 message: ''
//             });
//             setErrors({});
//         } catch (error) {
//             console.error('Error submitting form:', error);
//             // Handle error (e.g., show error message)
//         }
//     };

//     const errorStyle = {
//         color: 'red',
//         fontSize: '0.9em',
//         marginTop: '0.25em',
//     };

//     return (
//         <>
//             <section className="tp-appoint-contact-area pt-145 " style={{ backgroundImage: `url(assets/img/appoint/appoint-bg.jpg)` }}>
//                 <div className="container">
//                     <div className="row justify-content-lg-end justify-content-center">
//                         <div className="col-xl-6 col-lg-8 col-md-10">
//                             <div className="tp-appoint-contact">
//                                 <form onSubmit={handleSubmit} className="text-start tp-testimonial-two-form-wrapper">
//                                     <h3 className="tp-testimonial-form-title mb-30 text-white">Appointment</h3>
//                                     <div className="input-field mb-15">
//                                         <input
//                                             type="text"
//                                             placeholder="Your Name"
//                                             name="name"
//                                             value={formData.name}
//                                             onChange={handleChange}
//                                         />
//                                         {errors.name && <div style={errorStyle}>{errors.name}</div>}
//                                     </div>
//                                     <div className="input-field mb-15">
//                                         <input
//                                             type="text"
//                                             placeholder="Contact Number"
//                                             name="contactNumber"
//                                             value={formData.contactNumber}
//                                             onChange={handleChange}
//                                         />
//                                         {errors.contactNumber && <div style={errorStyle}>{errors.contactNumber}</div>}
//                                     </div>
//                                     <div className="input-field mb-15">
//                                         <input
//                                             type="email"
//                                             placeholder="Email Address"
//                                             name="email"
//                                             value={formData.email}
//                                             onChange={handleChange}
//                                         />
//                                         {errors.email && <div style={errorStyle}>{errors.email}</div>}
//                                     </div>
//                                     <div className="input-field select-field-arrow mb-15">
//                                         <select
//                                             name="service"
//                                             value={formData.service}
//                                             onChange={handleChange}
//                                         >
//                                             <option value="">Choose Service</option>
//                                             <option value="House Cleaning">House Cleaning</option>
//                                             <option value="Carpet Cleaning">Carpet Cleaning</option>
//                                             <option value="Kitchen Cleaning">Kitchen Cleaning</option>
//                                             <option value="Stairs Cleaning">Stairs Cleaning</option>
//                                             <option value="Sofa Cleaning">Sofa Cleaning</option>
//                                             <option value="Upholstery Cleaning">Upholstery Cleaning</option>
//                                             <option value="Deep Cleaning">Deep Cleaning</option>
//                                             <option value="Steam Cleaning">Steam Cleaning</option>
//                                             <option value="Rug Cleaning">Rug Cleaning</option>
//                                             <option value="Mattress Cleaning">Mattress Cleaning</option>
//                                         </select>
//                                         {errors.service && <div style={errorStyle}>{errors.service}</div>}
//                                     </div>
//                                     <div className="input-field mb-15">
//                                         <textarea
//                                             placeholder="Write Message"
//                                             name="message"
//                                             value={formData.message}
//                                             onChange={handleChange}
//                                         ></textarea>
//                                     </div>
//                                     <div className="input-field">
//                                         <button type="submit" className="yellow-btn">
//                                             <i className="flaticon-enter"></i> Submit Request
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default ApointArea;


import React, { useState } from 'react';
import axios from 'axios';

const ApointArea = () => {
    const [formData, setFormData] = useState({
        name: '',
        contactNumber: '',
        address: '',
        email: '',
        service: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = 'Please enter your name.';
        }
        if (!formData.contactNumber.trim()) {
            errors.contactNumber = 'Please enter your contact number.';
        } else if (!/^\d+$/.test(formData.contactNumber)) {
            errors.contactNumber = 'Contact number is invalid.';
        }
        if (!formData.address.trim()) {
            errors.address = 'Please enter your address.';
        }
        if (!formData.email.trim()) {
            errors.email = 'Please enter your email address.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email address is invalid.';
        }
        if (formData.service === '') {
            errors.service = 'Please choose a service.';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        try {
            const apiData = {
                full_name: formData.name,
                email: formData.email,
                mobile_no: formData.contactNumber,
                address: formData.address,
                message: formData.message,
                services: formData.service,
            };

            const response = await axios.post('https://cleaning.smilesdahub.com/api.php', apiData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log('Form submitted successfully', response.data);
            alert("Form Successfully submit");
            
            // Reset form
            setFormData({
                name: '',
                contactNumber: '',
                address: '',
                email: '',
                service: '',
                message: ''
            });
            setErrors({});
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error (e.g., show error message)
        }
    };

    const errorStyle = {
        color: 'red',
        fontSize: '0.9em',
        marginTop: '0.25em',
    };

    return (
        <>
            <section className="tp-appoint-contact-area pt-145 " style={{ backgroundImage: `url(assets/img/appoint/appoint-bg.jpg)` }}>
                <div className="container">
                    <div className="row justify-content-lg-end justify-content-center">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <div className="tp-appoint-contact">
                                <form onSubmit={handleSubmit} className="text-start tp-testimonial-two-form-wrapper">
                                    <h3 className="tp-testimonial-form-title mb-30 text-white">Appointment</h3>
                                    <div className="input-field mb-15">
                                        <input
                                            type="text"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        {errors.name && <div style={errorStyle}>{errors.name}</div>}
                                    </div>
                                    <div className="input-field mb-15">
                                        <input
                                            type="text"
                                            placeholder="Contact Number"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleChange}
                                        />
                                        {errors.contactNumber && <div style={errorStyle}>{errors.contactNumber}</div>}
                                    </div>
                                    <div className="input-field mb-15">
                                        <input
                                            type="text"
                                            placeholder="Address"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />
                                        {errors.address && <div style={errorStyle}>{errors.address}</div>}
                                    </div>
                                    <div className="input-field mb-15">
                                        <input
                                            type="email"
                                            placeholder="Email Address"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <div style={errorStyle}>{errors.email}</div>}
                                    </div>
                                    <div className="input-field select-field-arrow mb-15">
                                        <select
                                            name="service"
                                            value={formData.service}
                                            onChange={handleChange}
                                        >
                                            <option value="">Choose Service</option>
                                            <option value="House Cleaning">House Cleaning</option>
                                            <option value="Carpet Cleaning">Carpet Cleaning</option>
                                            <option value="Kitchen Cleaning">Kitchen Cleaning</option>
                                            <option value="Stairs Cleaning">Stairs Cleaning</option>
                                            <option value="Sofa Cleaning">Sofa Cleaning</option>
                                            <option value="Upholstery Cleaning">Upholstery Cleaning</option>
                                            <option value="Deep Cleaning">Deep Cleaning</option>
                                            <option value="Steam Cleaning">Steam Cleaning</option>
                                            <option value="Rug Cleaning">Rug Cleaning</option>
                                            <option value="Mattress Cleaning">Mattress Cleaning</option>
                                        </select>
                                        {errors.service && <div style={errorStyle}>{errors.service}</div>}
                                    </div>
                                    <div className="input-field mb-15">
                                        <textarea
                                            placeholder="Write Message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="input-field">
                                        <button type="submit" className="yellow-btn">
                                            <i className="flaticon-enter"></i> Submit Request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ApointArea;
