// import React from 'react';
// import { Link } from 'react-router-dom';
// const FloatingButton = () => {
//   return (
//     // <button className="floating-button">
//     // Contact Us
//     // </button>
//     <Link to="/contact" className="floating-button">
//       Contact Us
//     </Link>
//   );
// };

// const styles = `
//   .floating-button {
//     position: fixed;
//     bottom: 20px;
//     right: 20px;
//     width: 120px;
//     height: 50px;
//     background-color: #007bff;
//     color: white;
//     border: none;
//     border-radius: 40%;
//     font-size: 20px;
//     text-align: center; /* Center text horizontally */
//     line-height: 50px; /* Vertically center text */
//     text-decoration: none; /* Remove underline from anchor tag */
//     cursor: pointer;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     transition: background-color 0.3s ease;
//   }

//   .floating-button:hover {
//     background-color: #0056b3;
//   }

//   .floating-button:focus {
//     outline: none;
//   }
// `;

// const styleSheet = document.createElement('style');
// styleSheet.type = 'text/css';
// styleSheet.innerText = styles;
// document.head.appendChild(styleSheet);
// export default FloatingButton;

import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhone } from 'react-icons/fa';

const FloatingButton = () => {
  return (
    <Link to="/contact" className="floating-button">
      <FaPhone className="icon-phone" size={24} />
    </Link>
  );
};

const styles = `
  .floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    text-align: center; /* Center text horizontally */
    line-height: 60px; /* Vertically center text */
    text-decoration: none; /* Remove underline from anchor tag */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the button stays on top */
  }

  .floating-button:hover {
    background-color: #128c7e;
  }

  .floating-button:focus {
    outline: none;
  }

  .icon-phone {
    transform: rotate(90deg); 
    margin-right: 5px; 
    vertical-align: middle; 
  }
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default FloatingButton;
