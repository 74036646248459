
import React, { useState } from 'react';
import axios from 'axios';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from 'react-icons/fa';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        address: '',  // Added address field
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.fullName.trim()) {
            errors.fullName = 'Full name is required';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email address is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email address is invalid';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
        } else if (!/^\d+$/.test(formData.phone)) {
            errors.phone = 'Phone number is invalid';
        }
        if (!formData.address.trim()) {  // Added address validation
            errors.address = 'Address is required';
        }
        if (!formData.subject) {
            errors.subject = 'Please choose a subject';
        }
        if (!formData.message.trim()) {
            errors.message = 'Message is required';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            try {
                const apiData = {
                    full_name: formData.fullName,
                    email: formData.email,
                    mobile_no: formData.phone,
                    address: formData.address,  // Added address field
                    message: formData.message,
                    services: formData.subject,
                };

                const response = await axios.post('https://cleaning.smilesdahub.com/api.php', apiData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                console.log('Form submitted successfully', response.data);
                alert("Form Successfully submit");

                setFormData({
                    fullName: '',
                    email: '',
                    phone: '',
                    address: '',  // Reset address field
                    subject: '',
                    message: '',
                });
                setErrors({});
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            <section className="tp-contact-area pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="section-title-wrapper-two mb-50 wow fadeInUp" data-wow-delay=".2s">
                                <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">Get Free Estimate</h5>
                                <h2 className="tp-section-title heading-color-black">If you Have Any Query, Don’t Hesitate <br/>Contact with us </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="row custom-mar-20">
                                <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                                    <div className="tp-contact-info mb-40 wow fadeInUp" data-wow-delay=".4s">
                                        <div className="tp-contact-info-icon">
                                            <i> <FaMapMarkerAlt className='contact_icon'/> </i>
                                        </div>
                                        <div className="tp-contact-info-text">
                                            <h4 className="tp-contact-info-title mb-15">Address</h4>
                                            <p>63A Laburnum Terrace Huddersfield.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                                    <div className="tp-contact-info mb-40 wow fadeInUp" data-wow-delay=".6s">
                                        <div className="tp-contact-info-icon">
                                            <i> <FaPhoneAlt className='contact_icon'/> </i>
                                        </div>
                                        <div className="tp-contact-info-text">
                                            <h4 className="tp-contact-info-title mb-15">Phone</h4>
                                            <a href="tel:07776553416">07776553416</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
                                    <div className="tp-contact-info mb-40 wow fadeInUp" data-wow-delay=".8s">
                                        <div className="tp-contact-info-icon">
                                            <i > <FaEnvelopeOpen className='contact_icon'/> </i>
                                        </div>
                                        <div className="tp-contact-info-text">
                                            <h4 className="tp-contact-info-title mb-15">Email</h4>
                                            <a href="mailto:ask@theexpertcleaners.co.uk">ask@theexpertcleaners.co.uk</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="tp-contact-form">
                                <form onSubmit={handleSubmit}>
                                    <div className="row custom-mar-20">
                                        <div className="col-md-6 custom-pad-20">
                                            <div className="tp-contact-form-field mb-20">
                                                <input
                                                    type="text"
                                                    name="fullName"
                                                    placeholder="Full name"
                                                    value={formData.fullName}
                                                    onChange={handleChange}
                                                />
                                                {errors.fullName && <p className="error-message">{errors.fullName}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-pad-20">
                                            <div className="tp-contact-form-field mb-20">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <p className="error-message">{errors.email}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-pad-20">
                                            <div className="tp-contact-form-field mb-20">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone && <p className="error-message">{errors.phone}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-pad-20">
                                            <div className="tp-contact-form-field mb-20">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    placeholder="Address"  // Added address field
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                />
                                                {errors.address && <p className="error-message">{errors.address}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-pad-20">
                                            <div className="tp-contact-form-field select-field-arrow mb-20">
                                                <select
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Choose Subject</option>
                                                    <option value="House Cleaning">House Cleaning</option>
                                                    <option value="Carpet Cleaning">Carpet Cleaning</option>
                                                    <option value="Kitchen Cleaning">Kitchen Cleaning</option>
                                                    <option value="Stairs Cleaning">Stairs Cleaning</option>
                                                    <option value="Sofa Cleaning">Sofa Cleaning</option>
                                                    <option value="Upholstery Cleaning">Upholstery Cleaning</option>
                                                    <option value="Deep Cleaning">Deep Cleaning</option>
                                                    <option value="Steam Cleaning">Steam Cleaning</option>
                                                    <option value="Rug Cleaning">Rug Cleaning</option>
                                                    <option value="Mattress Cleaning">Mattress Cleaning</option>
                                                </select>
                                                {errors.subject && <p className="error-message">{errors.subject}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 custom-pad-20">
                                            <div className="tp-contact-form-field mb-20">
                                                <textarea
                                                    name="message"
                                                    placeholder="Your Message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.message && <p className="error-message">{errors.message}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 custom-pad-20">
                                            <div className="tp-contact-form-field">
                                                <button type="submit" className="theme-btn text-white">
                                                    <i className="flaticon-enter"></i> Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactForm;

// import React, { useState } from 'react';
// import axios from 'axios';
// import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpen } from 'react-icons/fa';

// const ContactForm = () => {
//     const [formData, setFormData] = useState({
//         fullName: '',
//         email: '',
//         phone: '',
//         subject: '',
//         message: '',
//     });

//     const [errors, setErrors] = useState({});

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value,
//         });
//     };

//     const validateForm = () => {
//         const errors = {};
//         if (!formData.fullName.trim()) {
//             errors.fullName = 'Full name is required';
//         }
//         if (!formData.email.trim()) {
//             errors.email = 'Email address is required';
//         } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
//             errors.email = 'Email address is invalid';
//         }
//         if (!formData.phone.trim()) {
//             errors.phone = 'Phone number is required';
//         } else if (!/^\d+$/.test(formData.phone)) {
//             errors.phone = 'Phone number is invalid';
//         }
//         if (!formData.subject) {
//             errors.subject = 'Please choose a subject';
//         }
//         if (!formData.message.trim()) {
//             errors.message = 'Message is required';
//         }
//         return errors;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const validationErrors = validateForm();
//         if (Object.keys(validationErrors).length === 0) {
//             try {
//                 // Prepare data to match the API's expected format
//                 const apiData = {
//                     full_name: formData.fullName,
//                     email: formData.email,
//                     mobile_no: formData.phone,
//                     message: formData.message,
//                     services: formData.subject,
//                 };

//                 // Send a POST request to your API endpoint
//                 const response = await axios.post('https://cleaning.smilesdahub.com/api.php', apiData, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 console.log('Form submitted successfully', response.data);
//                 alert("Form Successfully submit");
                
//                 // Reset form
//                 setFormData({
//                     fullName: '',
//                     email: '',
//                     phone: '',
//                     subject: '',
//                     message: '',
//                 });
//                 setErrors({});
//                 // Optionally, show a success message or perform other actions
//             } catch (error) {
//                 console.error('Error submitting form:', error);
//                 // Handle error (e.g., show error message)
//             }
//         } else {
//             // Form has errors
//             setErrors(validationErrors);
//         }
//     };

//     return (
//         <>
//             <section className="tp-contact-area pb-120">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-10">
//                             <div className="section-title-wrapper-two mb-50 wow fadeInUp" data-wow-delay=".2s">
//                                 <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">Get Free Estimate</h5>
//                                 <h2 className="tp-section-title heading-color-black">If you Have Any Query, Don’t Hesitate <br/>Contact with us </h2>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-lg-4">
//                             <div className="row custom-mar-20">
//                                 <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
//                                     <div className="tp-contact-info mb-40 wow fadeInUp" data-wow-delay=".4s">
//                                         <div className="tp-contact-info-icon">
//                                             <i> <FaMapMarkerAlt className='contact_icon'/> </i>
//                                         </div>
//                                         <div className="tp-contact-info-text">
//                                             <h4 className="tp-contact-info-title mb-15">Address</h4>
//                                             <p>63A Laburnum terrace Huddersfield.</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
//                                     <div className="tp-contact-info mb-40 wow fadeInUp" data-wow-delay=".6s">
//                                         <div className="tp-contact-info-icon">
//                                             <i> <FaPhoneAlt className='contact_icon'/> </i>
//                                         </div>
//                                         <div className="tp-contact-info-text">
//                                             <h4 className="tp-contact-info-title mb-15">Phone</h4>
//                                             <a href="tel:+44 7404 837712">+44 7404 837712</a>
//                                             {/* <a href="tel:444555300-25">444555300-25</a> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="col-lg-12 col-md-4 col-sm-6 custom-pad-20">
//                                     <div className="tp-contact-info mb-40 wow fadeInUp" data-wow-delay=".8s">
//                                         <div className="tp-contact-info-icon">
//                                             <i > <FaEnvelopeOpen className='contact_icon'/> </i>
//                                         </div>
//                                         <div className="tp-contact-info-text">
//                                             <h4 className="tp-contact-info-title mb-15">Email</h4>
//                                             <a href="mailto:ask@theexpertcleaners.co.uk">ask@theexpertcleaners.co.uk</a>
//                                             {/* <a href="mailto:info@themepure.com">info@themepure.com</a> */}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-lg-8">
//                             <div className="tp-contact-form">
//                                 <form onSubmit={handleSubmit}>
//                                     <div className="row custom-mar-20">
//                                         <div className="col-md-6 custom-pad-20">
//                                             <div className="tp-contact-form-field mb-20">
//                                                 <input
//                                                     type="text"
//                                                     name="fullName"
//                                                     placeholder="Full name"
//                                                     value={formData.fullName}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {errors.fullName && <p className="error-message">{errors.fullName}</p>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6 custom-pad-20">
//                                             <div className="tp-contact-form-field mb-20">
//                                                 <input
//                                                     type="email"
//                                                     name="email"
//                                                     placeholder="Email Address"
//                                                     value={formData.email}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {errors.email && <p className="error-message">{errors.email}</p>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6 custom-pad-20">
//                                             <div className="tp-contact-form-field mb-20">
//                                                 <input
//                                                     type="text"
//                                                     name="phone"
//                                                     placeholder="Phone"
//                                                     value={formData.phone}
//                                                     onChange={handleChange}
//                                                 />
//                                                 {errors.phone && <p className="error-message">{errors.phone}</p>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6 custom-pad-20">
//                                             <div className="tp-contact-form-field select-field-arrow mb-20">
//                                                 <select
//                                                     name="subject"
//                                                     value={formData.subject}
//                                                     onChange={handleChange}
//                                                 >
//                                                     <option value="">Choose Subject</option>
//                                                     <option value="House Cleaning">House Cleaning</option>
//                                                     <option value="Carpet Cleaning">Carpet Cleaning</option>
//                                                     <option value="Kitchen Cleaning">Kitchen Cleaning</option>
//                                                     <option value="Stairs Cleaning">Stairs Cleaning</option>
//                                                     <option value="Sofa Cleaning">Sofa Cleaning</option>
//                                                     <option value="Upholstery Cleaning">Upholstery Cleaning</option>
//                                                     <option value="Deep Cleaning">Deep Cleaning</option>
//                                                     <option value="Steam Cleaning">Steam Cleaning</option>
//                                                     <option value="Rug Cleaning">Rug Cleaning</option>
//                                                     <option value="Mattress Cleaning">Mattress Cleaning</option>
//                                                 </select>
//                                                 {errors.subject && <p className="error-message">{errors.subject}</p>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12 custom-pad-20">
//                                             <div className="tp-contact-form-field mb-20">
//                                                 <textarea
//                                                     name="message"
//                                                     placeholder="Your Message"
//                                                     value={formData.message}
//                                                     onChange={handleChange}
//                                                 ></textarea>
//                                                 {errors.message && <p className="error-message">{errors.message}</p>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12 custom-pad-20">
//                                             <div className="tp-contact-form-field">
//                                                 <button type="submit" className="theme-btn text-white">
//                                                     <i className="flaticon-enter"></i> Send Message
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default ContactForm;