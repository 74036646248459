import React from 'react';
import Navigation from '../../components/shared/Navigation/Navigation';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/shared/Footer/Footer';
import HomeServiceItem from '../../components/HomeServiceItem/HomeServiceItem';
const Birmingham = () => {
    return (
        <>
        <Navigation />
        <Breadcrumb title="Our Services" subTitle=" in Birmingham " smText='Birmingham City'></Breadcrumb>
        <section className="tp-services-area " style={{ backgroundColor: 'black' }}>
                <div className="tp-custom-container">
                    <div className="tp-services-bg grey-bg pt-120 pb-90 z-index">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="tp-section-title-wrapper text-center mb-55 wow fadeInUp" data-wow-delay=".1s">
                                        <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-25">Our Popular Services in Birmingham, UK</h5>
                                        <h2 className="tp-section-title">Our Success Cleaning <br />In this City</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <HomeServiceItem icon_name="pot" title="Kitchen Cleaning" link="KitchenService" data="Our kitchen cleaning services ensure that one of the most important areas of your home remains spotless, hygienic, and inviting. We understand that a clean kitchen is essential for the health and well-being of your family, and we're committed to providing top-notch cleaning services that meet your needs" />

                                <HomeServiceItem icon_name="vacuum-cleaner" title="Carpet Cleaning" link="CarpetService" data="Our professional carpet cleaning services are designed to revive and restore your carpets, making them look and feel like new. We use advanced cleaning techniques and eco-friendly products to remove dirt, stains, and allergens, ensuring a healthier living environment for you and your family." />

                                <HomeServiceItem icon_name="house-cleaning" title="House Cleaning" link="servicesDetails" data="Our house cleaning services are designed to provide you with a spotless, organized, and welcoming home. We understand the importance of a clean living space and strive to deliver top-quality cleaning services that exceed your expectations. Whether you need a one-time deep clean or regular maintenance." />

                                <HomeServiceItem icon_name="desk" title="Sofa Cleaning" link="SofaServices" data="Our sofa cleaning services are designed to restore the beauty and hygiene of your furniture. We use advanced techniques and eco-friendly products to ensure a thorough cleaning process that extends the life of your sofas and maintains a healthy indoor environment.Our team is dedicated to delivering outstanding results." />

                                <HomeServiceItem icon_name="cleaning" title="Stairs Cleaning" link="StairsServices" data="Our stairs cleaning services are tailored to enhance the appearance and safety of your staircase. We utilize specialized equipment and effective cleaning methods to ensure thorough cleaning without compromising the integrity of your stairs.Our meticulous cleaning process ensures that your stairs look pristine." />

                                <HomeServiceItem icon_name="window" title="Upholstery Cleaning" link="UpholsteryServices" data="Our upholstery cleaning services are designed to rejuvenate and maintain the beauty of your furniture. We employ advanced cleaning techniques and eco-friendly products to ensure a deep clean that enhances the lifespan of your upholstery and promotes a healthier indoor environment." />

                                <HomeServiceItem icon_name="window" title="Deep Cleaning" link="DeepServices" data="Our deep cleaning services ensure a thorough and meticulous clean for your home. Our team targets every corner, scrubbing floors, sanitizing high-touch surfaces, and deep-cleaning kitchens and bathrooms. Ideal for seasonal cleaning, moving, we leave your home spotless and rejuvenated." />

                                <HomeServiceItem icon_name="window" title="Steam Cleaning" link="SteamServices" data="Our steam cleaning services offer a powerful, eco-friendly clean for your home, using high-temperature steam to remove dirt, grime, and allergens from carpets, upholstery, and hard surfaces. Safe for families and pets, this chemical-free method leaves your home fresh and sanitized." />

                                <HomeServiceItem icon_name="window" title="Rug Cleaning" link="RugServices" data="Our rug cleaning services specialize in removing dirt, allergens, and stains, ensuring your rugs look vibrant and feel fresh. We use gentle yet effective techniques to preserve the integrity of your rugs while providing a thorough clean that enhances their longevity and appearance." />

                                {/* <HomeServiceItem icon_name="window" title="Mattress Cleaning" link="MattressCleaning" data="Our mattress cleaning services utilize advanced methods to remove dust mites, allergens, and stains. We ensure a deep clean that promotes a healthier sleeping environment, extends the lifespan of your mattress, and enhances your overall comfort and well-being.Our process is safe and eco-friendly." /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tp-map-area pt-120 pb-115">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tp-contact-map">
                                {/* <iframe title='contact map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830894612!2d-74.11976383964465!3d40.69766374865766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1636888514620!5m2!1sen!2sbd" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe> */}
                                <iframe
                                    title='contact map'
                                    src="https://maps.google.com/maps?q=52.489471,-1.898575&hl=es;&output=embed"
                                    style={{ border: 0, width: '100%', height: '500px' }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>

    );
};

export default Birmingham;