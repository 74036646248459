import React from 'react';
import Navigation from '../../components/shared/Navigation/Navigation';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/shared/Footer/Footer';
import HomeServiceItem from '../../components/HomeServiceItem/HomeServiceItem';
const Manchester = () => {
    return (
        <>
            <Navigation />
            <Breadcrumb title="Our Services" subTitle=" in Manchester " smText='Manchaster City'></Breadcrumb>
            <section className="tp-services-area " style={{ backgroundColor: 'black' }}>
                <div className="tp-custom-container">
                    <div className="tp-services-bg grey-bg pt-120 pb-90 z-index">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="tp-section-title-wrapper text-center mb-55 wow fadeInUp" data-wow-delay=".1s">
                                        <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-25">Our Popular Services in Manchester, UK</h5>
                                        <h2 className="tp-section-title">Our Success Cleaning <br />In this City</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <HomeServiceItem icon_name="pot" title="Kitchen Cleaning" link="KitchenService" data="We deep clean your kitchen by removing the deepest, stubborn grease, sanitizing surfaces, and providing a sparkling kitchen that is not only clean but hygienic as well. Our team focuses on high-contact areas, appliances, and dark spots to ensure creating a gleaming cooking environment—all this with the help of safe, effective cleaning solutions" />

                                <HomeServiceItem icon_name="vacuum-cleaner" title="Carpet Cleaning" link="CarpetService" data="Our professional carpet cleaning services get rid of stains and allergens from your carpets so that your home remains fresh. Our carpet cleaning services are a comprehensive solution that provides effective stain removal and dirt, not to mention the allergens that leave your carpets looking and feeling as new. We use a non-toxic formula to clean." />

                                <HomeServiceItem icon_name="house-cleaning" title="House Cleaning" link="servicesDetails" data="Our house deep cleaning services cover all rooms in the house with expert and keen eye to every corner.Our house deep cleaning services take care of dusting and vacuuming to sanitization, making everything look brand new. We take care of every detail for your house to look clean but, above all, to be healthier and more comfortable for you." />

                                <HomeServiceItem icon_name="desk" title="Sofa Cleaning" link="SofaServices" data="Our comprehensive sofa deep cleaning services will remove dirt and stains, in turn restoring your upholstery and extending its life.Our specialized cleaning methods can eliminate all possible kinds of stains and odors, bringing back the fresh look and feel of your furniture. We are very gentle but clean your sofas very efficiently." />

                                <HomeServiceItem icon_name="cleaning" title="Stairs Cleaning" link="StairsServices" data="Our stair-cleaning service is provided by the removal of dirt and filth from steps and handrails. This way, we make your home or office clean, yet additionally safe for its inhabitants. Our keen eyes for details give the leeway of having effective methods using green products to result in absolutely pure and hygienic stairs." />

                                <HomeServiceItem icon_name="window" title="Upholstery Cleaning" link="UpholsteryServices" data="Our upholstery cleaning services work out all the deeply ingrained dirt and allergens by very soft but effective methods, thus retaining the beauty and comfort of your furnishings. We take utmost care to see that your things get treated for a thorough clean, increasing the life of your upholstery." />

                                <HomeServiceItem icon_name="window" title="Deep Cleaning" link="DeepServices" data="Our deep-cleaning service is very powerful and provides the best service possible for periodical maintenance or special occasions.Our professional deep cleaning services will ensure that your property is scrubbed and free from dirt and germs in all corners. We use advanced techniques and products that are eco-friendly to give you comprehensive cleanliness." />

                                <HomeServiceItem icon_name="window" title="Steam Cleaning" link="SteamServices" data="Our steam cleaning harnesses temperature steam, which is high on temperature, to remove dirt, bacteria, and allergens for an impeccably safe clean on every surface. This chemical-free technique ensures that you get a hygienic and eco-friendly solution for cleaning.With our steam cleaning service, do a perfect, deep ecological cleanup with disinfection." />

                                <HomeServiceItem icon_name="window" title="Rug Cleaning" link="RugServices" data="Our oriental rug cleaning service is carried out with gentle handling and proven cleaning procedures to ensure the stain and dirt is taken out for enhanced beauty and long-lasting life of your rugs. We use only safe and environment-friendly products so that the color of your rugs remains clean and colorful.We conduct specialized cleaning of rugs to preserve." />

                                {/* <HomeServiceItem icon_name="window" title="Mattress Cleaning" link="MattressCleaning" data="Our mattress cleaning services utilize advanced methods to remove dust mites, allergens, and stains. We ensure a deep clean that promotes a healthier sleeping environment, extends the lifespan of your mattress, and enhances your overall comfort and well-being.Our process is safe and eco-friendly." /> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="tp-map-area pt-120 pb-115">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tp-contact-map">
                                {/* <iframe title='contact map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830894612!2d-74.11976383964465!3d40.69766374865766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1636888514620!5m2!1sen!2sbd" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe> */}
                                <iframe
                                    title='contact map'
                                    src="https://maps.google.com/maps?q=53.483959,-2.244644&hl=es;&output=embed"
                                    style={{ border: 0, width: '100%', height: '500px' }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
};

export default Manchester;