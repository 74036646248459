import React from 'react';
import ServicePageArea from '../../../../components/ServiceArea/ServicePageArea';

const ServicesArea = () => {
   return (
      <>
         <section className="tp-service-area-three pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title-wrapper text-center mb-55 wow fadeInUp" data-wow-delay=".3s">
                        <h5 className="tp-section-subtitle section__sm__title common-yellow-shape mb-20 heading-color-black">Our Best Services</h5>
                        <h2 className="tp-section-title heading-color-black">Quality Cleaning Making <br/>you Much Happy</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
               
               <ServicePageArea service_image_num="9" ser_icon_img="house-cleaning" 
               ser_title="House Cleaning" link="servicesDetails" data="Our professional house deep cleaning services cover all rooms in the house with expert and keen eye to every corner."></ServicePageArea>

               <ServicePageArea service_image_num="10" ser_icon_img="cleaning" 
               ser_title="Carpet Cleaning" link="CarpetService" data="With our Carpet cleaning services you can get rid of stains and allergens from your carpets so that your home remains fresh."></ServicePageArea>

               <ServicePageArea service_image_num="11" ser_icon_img="pot" 
               ser_title="Kitchen Cleaning" link="KitchenService" data="Our kitchen deep cleaning services ensure a spotless and hygienic cooking space; we take on grease and grime efficiently"></ServicePageArea>

               <ServicePageArea service_image_num="12" ser_icon_img="window" 
               ser_title="Stairs Cleaning" link="StairsServices" data="Our professional team carefully clean and disinfect stairs, thus providing the safest and cleanest high-traffic areas."></ServicePageArea>

               <ServicePageArea service_image_num="13" ser_icon_img="desk" 
               ser_title="Sofa Cleaning" link="SofaServices" data="Our comprehensive sofa deep cleaning services will remove dirt and stains, in turn restoring your upholstery and extending its life."></ServicePageArea>

               <ServicePageArea service_image_num="14" ser_icon_img="vacuum-cleaner" 
               ser_title="Upholstery Cleaning" link="UpholsteryServices" data="Our upholstery cleaning refreshes and protects your furniture from dust, allergens, and stains."></ServicePageArea>

               <ServicePageArea service_image_num="15" ser_icon_img="vacuum-cleaner" 
               ser_title="Deep Cleaning" link="DeepServices" data="Our deep-cleaning service is very powerful and provides the best service possible for periodical maintenance or special occasions."></ServicePageArea>

               <ServicePageArea service_image_num="16" ser_icon_img="vacuum-cleaner" 
               ser_title="Steam Cleaning" link="SteamServices" data="With our steam cleaning service, do a perfect, deep ecological cleanup with disinfection; all this is done without harsh chemicals."></ServicePageArea>

               <ServicePageArea service_image_num="17" ser_icon_img="vacuum-cleaner" 
               ser_title="Rug Cleaning" link="RugServices" data="Our rug cleaning services are designed to restore the beauty of your rug, regardless of how big or small you have it or the material it is made from"></ServicePageArea>

               <ServicePageArea service_image_num="18" ser_icon_img="vacuum-cleaner" 
               ser_title="Mattress Cleaning" link="MattressCleaning" data="Our mattress cleaning service kills dust mites, removes all kinds of allergens, and stains for a healthier sleep environment."></ServicePageArea>

               </div>
            </div>
         </section>
      </>
   );
};

export default ServicesArea;