// import React from 'react';
// import { FaMapMarkerAlt } from 'react-icons/fa'; // Importing the icon
// import Navigation from '../components/shared/Navigation/Navigation';

// const areas = [
//     "Manchester",
//     "West Yorkshire",
//     "Leeds",
//     "Bradford",
//     "Wakefield",
//     "Huddersfield",
//     "Halifax",
//     "South Yorkshire",
//     "Sheffield",
//     "Doncaster",
//     "Rotherham",
//     "Barnsley",
//     "North Yorkshire",
//     "York",
//     "Harrogate",
//     "Scarborough",
//     "Ripon",
//     "Northallerton",
//     "East Riding of Yorkshire",
//     "Kingston upon Hull (Hull)",
//     "Bridlington",
//     "Beverley"
// ];

// const styles = {
//     container: {
//         textAlign: 'center',
//         fontFamily: 'Arial, sans-serif',
//         marginBottom:'50px',
//         marginTop:'50px',
//     },
//     heading: {
//         fontSize: '24px',
//         marginTop: '20px'
//     },
//     description: {
//         marginTop: '10px',
//         marginBottom: '20px',
//         fontSize: '16px',
//         color: '#555'
//     },
//     list: {
//         display: 'flex',
//         justifyContent: 'center',
//         flexWrap: 'wrap',
//         gap: '10px',
//         listStyle: 'none',
//         padding: '0'
//     },
//     listItem: {
//         fontSize: '16px',
//         color: '#00b3ff',
//         display: 'flex',
//         alignItems: 'center'
//     },
//     icon: {
//         marginRight: '5px'
//     }
// };

// const AreasWeCover = () => {
//     return (
//         <>
//         {/* <Navigation/> */}
//         <div style={styles.container}>
//             <h2 style={styles.heading}>Areas We Cover</h2>
//             <p style={styles.description}>
//                 You can schedule our Deep Cleaning Services on all postcodes in:
//             </p>
//             <ul style={styles.list}>
//                 {areas.map((area, index) => (
//                     <li key={index} style={styles.listItem}>
//                         <FaMapMarkerAlt style={styles.icon} /> {area}
//                     </li>
//                 ))}
//             </ul>
//         </div>
//         {/* <iframe src="https://www.google.com/maps/d/embed?mid=1hrln8NM0Sb3qVIIRnePWphKgHyrxp9Y&ehbc=2E312F" 
//             width="1270" height="600" ></iframe> */}
//             <div class="responsive-iframe">
//         <iframe src="https://www.google.com/maps/d/embed?mid=1hrln8NM0Sb3qVIIRnePWphKgHyrxp9Y&ehbc=2E312F"></iframe>
//     </div>
//         </>
//     );
// };

// export default AreasWeCover;


import React from 'react';
import Areas from './cities';

const ResponsiveIframe = () => {
  // Embed CSS directly in the component
  const styles = `
    .responsive-iframe {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /* 16:9 aspect ratio */
    }
  
    .responsive-iframe iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  
    @media (min-height: 1500px) {
      .responsive-iframe {
        height: 100%;
        padding-bottom: 0;
      }
    }
  `;

  return (
    <>
    <Areas/>
      <style>{styles}</style>
      <div className="responsive-iframe">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1hrln8NM0Sb3qVIIRnePWphKgHyrxp9Y&ehbc=2E312F"
          title="Google Map"
        ></iframe>
      </div>
    </>
  );
};

export default ResponsiveIframe;

