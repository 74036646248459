import React from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane } from 'react-icons/fa';
import { FaMapMarkerAlt, FaEnvelopeOpen, FaFacebookF ,FaPhone} from 'react-icons/fa';
import { AiOutlineGoogle } from 'react-icons/ai';
import { BsTwitter, BsInstagram, BsYoutube } from 'react-icons/bs';

const Footer = () => {
   const currentYear = new Date().getFullYear();
   return (

      <>
         <footer className="theme-dark-bg" style={{ backgroundColor: 'black' }}>
            <div className="tp-footer-subscribe-area-two position-relative ">
               <div className="container">
                  {/* <div className="tp-footer-subscribe-bg-two  pt-30 pb-20 z-index pl-60 pr-60" style={{ backgroundColor: 'black' }}>
                     <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-4">
                           <div className="tp-footer-subscribe">
                              <h3 className="tp-footer-subscribe-title"  style={{ color: 'white' }}>Subscribe Our Newsletter</h3>
                           </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                           <div className="tp-footer-subscribe-form">
                              <form action="#" className="p-0">
                                 <div className="tp-footer-subscribe-form-field mb-10">
                                    <input type="text" placeholder="Email Address"/>
                                       <i ><FaPaperPlane/></i>
                                 </div>
                                 <div className="tp-footer-subscribe-form-btn mb-10">
                                    <button type="submit" className="theme-btn text-white"><i className="flaticon-enter"></i> Subscribe</button>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div> */}
               </div>
            </div>
            <div className="tp-footer-area-two pt-15 ">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-5 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp" data-wow-delay=".6s">
                           <h4 className="tp-footer-widget-title mb-35">We are <br></br>The Expert Cleaners</h4>
                           <p className="tp-footer-widget-para mb-35">where Cleanliness meets comfort, and your home finds its sparkle!</p>
                           <h4 className="tp-footer-widget-title mb-35"> Open Hours </h4>
                           <p className="tp-footer-widget-para mb-35">Mon - Sun: 8am - 10pm <br></br> Sunday Closed</p>
                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp" data-wow-delay=".6s">
                           <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                           <ul>
                              <li><Link to="/CarpetService">Carpet Cleaning</Link></li>
                              <li><Link to="#">House Cleaning</Link></li>
                              <li><Link to="/StairsServices">Stairs Cleaning</Link></li>
                              <li><Link to="/KitchenService">Kitchen Cleaning</Link></li>
                              <li><Link to="/SofaServices">Sofa Cleaning</Link></li>
                              <li><Link to="/UpholsteryServices">Upholstery Cleaning</Link></li>
                           </ul>

                        </div>
                     </div>
                     <div className="col-lg-3 col-sm-6">
                        <div className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp" data-wow-delay=".3s">
                           <div className="tp-footer-info">
                              <div className="tp-footer-info-logo mb-35">
                                 <Link to="/"><img src="assets/img/logo/logo-expert.png" className="img-fluid" alt="img not found" /></Link>
                              </div>
                              <h4 className="mb-15 ml-25"> <i className="icon-phone" > <FaPhone /> </i><a href="tel:07776553416">07776553416</a></h4>
                              <h6 className="mb-15 ml-25"> <i > <FaEnvelopeOpen /> </i><a href="mailto:ask@theexpertcleaners.co.uk">ask@theexpertcleaners.co.uk</a></h6>
                              <h6 className="mb-20 ml-25"> <i> <FaMapMarkerAlt /> </i> 63A Laburnum terrace Huddersfield</h6>
                              <div className="tp-footer-info-social ml-25">
                                 <a href="/"><i><FaFacebookF className='icon' /> </i></a>
                                 {/* <a href="/"><i><BsTwitter className='icon' /> </i></a> */}
                                 <a href="/"><i><BsInstagram className='icon' /> </i></a>
                                 <a href="/"><i><BsYoutube className='icon' /> </i></a>
                                 {/* <a href="/"><i><AiOutlineGoogle className='icon' /> </i></a> */}
                              </div>
                           </div>
                        </div>
                     </div>


                  </div>
               </div>
            </div>

            <div className="tp-copyright-area-two z-index mt-5 pb-2" style={{ backgroundColor: '#black' }}>
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <div className="tp-copyright tp-copyright-two text-center">
                           <p className="m-0">Copyright ©{currentYear}. <span >Developed by: TOG </span>. All Rights Reserved </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;