import React from 'react';
import HomeServiceItem from '../../../../components/HomeServiceItem/HomeServiceItem';

const ServiceArea = () => {
   return (
      <>
         <section className="tp-services-area "style={{ backgroundColor: 'black' }}>
            <div className="tp-custom-container">
               <div className="tp-services-bg grey-bg pt-120 pb-90 z-index">
                  <div className="container">
                     <div className="row justify-content-center">
                        <div className="col-lg-8">
                           <div className="tp-section-title-wrapper text-center mb-55 wow fadeInUp" data-wow-delay=".1s">
                              <h5 className="tp-section-title  mb-25">Our Popular Services</h5>
                              <h2 className="tp-section-subtitle section__sm__title ">Our Success Cleaning <br />Up your Mess</h2>
                           </div>
                        </div>
                     </div>
                     <div className="row">

                        <HomeServiceItem icon_name="pot" title="Kitchen Cleaning" link="KitchenService" data="Our kitchen deep cleaning services ensure a spotless and hygienic cooking space; we take on grease and grime efficiently" />

                        <HomeServiceItem icon_name="vacuum-cleaner" title="Carpet Cleaning" link="CarpetService" data="With our Carpet cleaning services you can get rid of stains and allergens from your carpets so that your home remains fresh." />

                        <HomeServiceItem icon_name="house-cleaning" title="House Cleaning" link="servicesDetails" data="Our professional house deep cleaning services cover all rooms in the house with expert and keen eye to every corner." />

                        <HomeServiceItem icon_name="desk" title="Sofa Cleaning" link="SofaServices" data="Our comprehensive sofa deep cleaning services will remove dirt and stains, in turn restoring your upholstery and extending its life." />

                        <HomeServiceItem icon_name="cleaning" title="Stairs Cleaning" link="StairsServices" data="Our professional team carefully clean and disinfect stairs, thus providing the safest and cleanest high-traffic areas." />

                        <HomeServiceItem icon_name="window" title="Upholstery Cleaning" link="UpholsteryServices" data="Our upholstery cleaning refreshes and protects your furniture from dust, allergens, and stains." />

                        <HomeServiceItem icon_name="window" title="Deep Cleaning" link="DeepServices" data="Our deep-cleaning service is very powerful and provides the best service possible for periodical maintenance or special occasions." />

                        <HomeServiceItem icon_name="window" title="Steam Cleaning" link="SteamServices" data="With our steam cleaning service, do a perfect, deep ecological cleanup with disinfection; all this is done without harsh chemicals." />

                        <HomeServiceItem icon_name="window" title="Rug Cleaning" link="RugServices" data="Our rug cleaning services are designed to restore the beauty of your rug, regardless of how big or small you have it or the material it is made from." />

                        {/* <HomeServiceItem icon_name="window" title="Mattress Cleaning" link="MattressCleaning" data="Our mattress cleaning service kills dust mites, allergens, and removes stains for a healthier sleep environment.We make your sleeping zone allergen-free and stain-free with in-depth cleaning procedures through our mattress cleaning service. We use safe and effective products to ensure a fresh and hygienic mattress." /> */}

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ServiceArea;