// // src/GoogleReviews.js

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const GoogleReviews = () => {
//   const [reviews, setReviews] = useState([]);
//   const placeId = 'ChIJ6adtYMgEGTkR1yB3j7goCtc'; // Replace with your place ID
//   const apiKey = 'AIzaSyCfv2GYtCllusUluYK98xzLqAsefWBOgGU'; // Replace with your API key

//   useEffect(() => {
//     const fetchReviews = async () => {
//       try {
//         const response = await axios.get(
//           `/api/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`
//         );
//         setReviews(response.data.result.reviews);
//       } catch (error) {
//         console.error('Error fetching reviews:', error);
//       }
//     };

//     fetchReviews();
//   }, []);

//   return (
//     <div>
//       <h2>Google Reviews</h2>
//       {reviews.length > 0 ? (
//         <ul>
//           {reviews.map((review, index) => (
//             <li key={index}>
//               <p><strong>{review.author_name}</strong>: {review.text}</p>
//               <p>Rating: {review.rating}</p>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <p>No reviews available.</p>
//       )}
//     </div>
//   );
// };

// export default GoogleReviews;


import React, { useEffect } from 'react';

const GoogleReviews = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.defer = true;
    script.dataset.useServiceCore = true;
    // Append the script element to the document head
    document.head.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="elfsight-app-a82def99-3969-4a30-ab8c-9a90017fb7f7 "  data-elfsight-app-lazy></div>
  );
};

export default GoogleReviews;
