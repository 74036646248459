import React from 'react';

const ContactMap = () => {
    return (
        <>
            <div className="tp-map-area pt-120 pb-115">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="tp-contact-map">
                                {/* <iframe title='contact map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830894612!2d-74.11976383964465!3d40.69766374865766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1636888514620!5m2!1sen!2sbd" style={{ border: 0 }} allowFullScreen="" loading="lazy"></iframe> */}
                                <iframe
                                    title='contact map'
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.850329839085!2d-1.8420738!3d53.6418069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bdca5de3a4bd3%3A0xa294aeac828d3fc2!2s63%20Laburnum%20Grove%2C%20Golcar%2C%20Huddersfield%20HD7%204BA%2C%20UK!5e0!3m2!1sen!2sbd!4v1636888514620!5m2!1sen!2sbd"
                                    style={{ border: 0, width: '100%', height: '500px' }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ContactMap;