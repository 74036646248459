import React, { useState } from 'react';
import Footer from '../../components/shared/Footer/Footer';
import Navigation from '../../components/shared/Navigation/Navigation';
import { CgCheckO } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ContactForm from '../Contact/Contact/ContactForm/ContactForm';

const MattressCleaning = () => {
  return (
    <>
      <Navigation />
      <Breadcrumb title="Mattress" subTitle="Cleaning" smText='Mattress Cleaning'></Breadcrumb>
      <section className="tp-service-details-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="tp-faqs-left">
                <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
                  <h4 className="tp-faqs-left-sidebar-title">Category...</h4>
                  <ul>
                    <li>
                      <Link to="/KitchenService">
                        Kitchen Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/servicesDetails " >
                        House Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/CarpetService">
                        Carpet Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SofaServices" >
                        Sofa Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/StairsServices">
                        Stairs Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/UpholsteryServices">
                        Upholstery Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/DeepServices">
                        Deep Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SteamServices">
                        Steam Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/RugServices">
                        Rug Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/MattressCleaning" style={{ color: 'blue' }}>
                        Mattress Cleaning <i> <FaArrowAltCircleRight /> </i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tp-faqs-left-img mb-30">
                  {/* <a href="#"><img src="assets/img/about/faq-img-1.jpg" className="img" alt="img not found" /></a> */}
                </div>
                {/* <div className="tp-faqs-left-sidebar tp-services-sidebar mb-30">
                  <h4 className="tp-faqs-left-sidebar-title mb-30">Category...</h4>
                  <div className="tp-services-sidebar-btn">
                    <a href="#" className="mb-15 text-white"><i className="flaticon-pdf-file"></i> Presentation</a>
                    <a href="#" className="mb-10 text-white"><i className="flaticon-file"></i> Download.txt</a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="tp-service-details">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="tp-service-details-img mb-30">
                      <img src="assets/img/service/service-details-11a.jpg" alt="img not found" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="tp-service-details-img mb-30">
                      <img src="assets/img/service/service-details-11b.jpg" alt="img not found" />
                    </div>
                  </div>
                </div>
                {/* <h2 className="tp-section-title heading-color-black pt-10 mb-20">Our Mission</h2>
                <p className="mb-20">The Expert Cleaning's mission is to provide top-class cleaning solutions for residential clients. We strive to enhance hygiene and aesthetic appeal by paying close attention to detail and committing to quality. With a mix of our professional knowledge and modern cleaning methods, we work to offer healthy surroundings that provide well-being and delight for all our important clients.</p>
                <h3 className="tp-service-details-subtitle">Our commitment</h3>
                <p className="mb-40">We are committed to the best quality and reliability standards in the delivery of professional deep cleaning services. Our team is very committed to continuous improvement, which is the only way forward to being up to date with trends and innovation in this industry. We promise to treat your space with respect and care, giving you customized solutions for cleaning that reflect our passion for excellence. You can trust us to keep your environment spotless, safe, and inviting.</p> */}
                <h3 className="tp-service-details-subtitle">Cleaning Process </h3>
                <p className="mb-40">The cleaning process by our experts is very detailed, and we use new techniques with eco-friendly products. We ensure that each surface is left spotless and sanitized, giving a clean, healthy, and hygienic environment for your house or office.Our cleaning procedure at The Expert Cleaning is known to be top-notch, delivering outstanding results. We start with a thorough inspection of your area to know the major key areas that need attention. Our team cleans up dirt, grime, and stains with eco-friendly products and the latest in equipment. We focus on surface sanitization, allergens extraction, and deep cleaning in all corners and crevices, maximizing general hygiene and aesthetics. Trust our professional deep cleaning services for a sparkling and welcoming space.</p>
                <h3 className="tp-service-details-subtitle">Mattress Cleaning </h3>
                <p className="mb-40">Using our mattress cleaning service, we ensure your sleeping area is allergen- and stain-free. We always prefer using safe and effective products for the mattress cleaning service to ensure a fresh and hygienic mattress.</p>
                <div className="tp-service-details-img mb-30 position-relative">
                  {/* <img src="assets/img/service/service-details-3.jpg" alt="img not found" /> */}
                  <div className="tp-service-details-img-overlay">
                    <div className="tp-service-details-img-overlay-icon">
                      {/* <button onClick={() => setOpen(true)} className="venobox" data-autoplay="true" data-vbtype="video" href="https://youtu.be/o4GuSJYSzrY">
                        <i > <FaPlay className="text-white" /> </i>
                      </button> */}
                    </div>
                  </div>
                </div>
                <h3 className="tp-service-details-subtitle">Why Choose The Expert Cleaning for Mattress Cleaning</h3>
                <p className="mb-25 d-flex"> <i > <CgCheckO /> </i>Deep Cleaning: Assists in removing allergens and stains.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Healthy Surroundings: Improved sleep hygiene.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Safe Chemicals: Environmentally friendly cleaning solutions.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Experienced Team: Expert staff for mattress cleaning.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Cost-Effective: Services offered at economical prices for mattress cleaning.</p>
                <h3 className="tp-service-details-subtitle mb-20">Why Choose us</h3>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Expert Team: Our experienced cleaners know how to handle even the toughest dirt with precision and care.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Green Cleaning: We provide sustainable cleaning solutions that are safe for the family and pets yet tough on dirt to give you a level of clean you desire.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Customized Solutions: We offer flexible customized cleaning schedules according to your needs and availability, offering you convenience and peace of mind.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Affordable Pricing: Experience top-notch cleaning services at set prices with no hidden fees or surprise charges.</p>
                <p className="mb-35 d-flex"> <i > <CgCheckO /> </i>Satisfaction Guaranteed: Your satisfaction is our priority. We go the extra mile to ensure that you are wowed with our services.</p>
                {/* <div className="row">
                  <div className="col-xl-4 col-md-6">
                    <div className="tp-service-details-feature text-center bg-gray-light mb-40">
                      <div className="tp-feature-three-icon mb-25">
                        <img src="assets/img/icon/service-feature.png" className="img-fluid" alt="img not found" />
                        <i className="flaticon-booking"></i>
                      </div>
                      <h4 className="tp-service-details-feature-title">
                        <Link to="/servicesDetails">Information for Any <br />Services</Link></h4>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="tp-service-details-feature text-center bg-gray-light mb-40">
                      <div className="tp-feature-three-icon mb-25">
                        <img src="assets/img/icon/service-feature.png" className="img-fluid" alt="img not found" />
                        <i className="flaticon-delivery-box"></i>
                      </div>
                      <h4 className="tp-service-details-feature-title">
                        <Link to="/servicesDetails">Customer Support <br />Live Chat</Link></h4>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6">
                    <div className="tp-service-details-feature text-center bg-gray-light mb-40">
                      <div className="tp-feature-three-icon mb-25">
                        <img src="assets/img/icon/service-feature.png" className="img-fluid" alt="img not found" />
                        <i className="flaticon-boy"></i>
                      </div>
                      <h4 className="tp-service-details-feature-title">
                        <Link to="/servicesDetails">Book Appointment <br />in online</Link></h4>
                    </div>
                  </div>
                </div> */}
                {/* <h3 className="tp-service-details-subtitle mb-20">For Sparkling Results</h3>
                <p className="mb-40">Curae tincidunt posuere ad tristique fusce nam quisque in pretium morbi risus orci volutpat non in quam cum praesent auctor tellus penatibus orci litora donec posuere etiam commodo fermentum ultricies habitant scelerisque. Ipsum torquent venenatis congue biben dum mauris. A egestas primis convallis dictum phasellus laoreet magnis felis iaculis vel parturient interdum conubia adse accumsan pharetra enim dictumst. Tincidunt massa amet magna facilisi quis convallis congue. Fringilla aliquet dolor gravida class libero cubilia pulvinar etiam platea ace. Elementum eu eget vulputate curabitur vestibulum feugiat sociosqu lacinia porta viverra mollis magnis rutrum bibendum proin facilisi duis consequat risus sociosqu volutpat proin.</p> */}
                {/* <div className="row">
                  <div className="col-xl-6">
                    <div className="tp-service-details-img mb-30">
                      <img src="assets/img/service/service-details-4.jpg" alt="img not found" />
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <p className="mb-20">Posuere ad tristique fusce nam quisque in pretium morbi risus orci volutpat non in the quam cum praesent auctor tellus penatibus orci litora donec posuere etiam commo doing to fermentum ultricies habitant scelerisque. Ipsum torquent venenatis congue biben dum mauris primis con vallis dictum phasellus laoreet magnis felis iaculis vele parturient interdum conubia adse be accumsan pharetra enim dictumst. Tincidunt massa amet magna facilisi quis convallis congue. Fringilla aliquet dolor to gravida class libero cubilia pulvinar etiam platea</p>
                    <p>Habitasse sed orci lorem lorem quis conubia nam amet lobortis hymenaeos tellus ad nunc senectus auctor varius lamcorper neque faucibus nam ridiculus posuere. Sollici tudin nullam sociis molestie vestibulum to commodo dui tellus eget quam, natoque et. Nonummy bes vehicula leo sollicitudin cras Sociis pretium ligula donec sagittis inter dum aptent nam penatibus dolormagna eu pharetra.</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <Footer />
    </>
  );
};

export default MattressCleaning;