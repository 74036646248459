import React from 'react';
import { Link } from 'react-router-dom';

const ChoseArea = () => {
    return (
        <>
            <section className="tp-choose-area position-relative pt-40 pb-90">
            {/* <div className="tp-choose-silver-bg "></div> */}
            <div
            style={{
                height: '590px',
                position: 'absolute',
                top: '-255px',
                left: '0px',
                right: '0px',
                backgroundColor: '#9ACD32',
                width: '100%',
                zIndex: '1'
            }}
        ></div>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-6 col-lg-8">
                        <div className="tp-choose z-index">
                            <div className="tp-section-title-wrapper mb-105 wow fadeInUp" data-wow-delay=".2s">
                                <h5 className="tp-section-subtitle common-yellow-shape common-white-shape section__sm__title">Why Choose The Expert Cleaners</h5>
                                <h2 className="tp-section-title">Expert House Cleaning Service Can Trust</h2>
                            </div>
                            <div className="tp-choose-img mb-40 z-index d-block d-xl-none">
                                <img src="assets/img/about/choose-img-1.jpg" alt="img not found"/>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="tp-choose-timeline ml-15 mb-30">
                                        <div className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp" data-wow-delay=".4s">
                                            <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                                              {/* <Link to="/servicesDetails">Expert Team </Link> */}
                                              <h3>Expert Team </h3>
                                             </h4>
                                            <p>Our experienced cleaners know how to handle even the toughest dirt with precision and care.</p>
                                        </div>
                                        <div className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp" data-wow-delay=".6s">
                                            <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color">
                                            <h3>Green Cleaning </h3>
                                                </h4>
                                            <p>We provide sustainable cleaning solutions that are safe for the family and pets yet tough on dirt to give you a level of clean you desire.</p>
                                        </div>
                                        <div className="tp-choose-timeline-single pl-35 wow fadeInUp" data-wow-delay=".8s">
                                            <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color"><h3>Customized Solutions</h3></h4>
                                            <p>We offer flexible customized cleaning schedules according to your needs and availability, offering you convenience and peace of mind.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="tp-choose-timeline ml-15 mb-30">
                                        <div className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp" data-wow-delay="1s">
                                            <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color"><h3>Affordable Pricing</h3></h4>
                                            <p>Experience top-notch cleaning services at set prices with no hidden fees.</p>
                                        </div>
                                        <div className="tp-choose-timeline-single mb-55 pl-35 wow fadeInUp" data-wow-delay="1.2s">
                                            <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color"><h3>Satisfaction Guaranteed</h3></h4>
                                            <p>Your satisfaction is our priority. We go the extra mile to ensure that you are wowed with our services.</p>
                                        </div>
                                        <div className="tp-choose-timeline-single pl-35 wow fadeInUp" data-wow-delay="1.4s">
                                            <h4 className="tp-choose-timeline-single-title mb-15 hover-theme-color"><h3>LIve Support</h3></h4>
                                            <p>We provide round-the-clock, 24/7 support for all your needs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-12">
                        <div className="tp-choose-img mb-30 z-index d-none d-xl-block wow fadeInUp" data-wow-delay=".6s">
                            <img src="assets/img/about/choose-img-1.jpg" alt="img not found"/>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
        </>
    );
};

export default ChoseArea;