// import React, { useState } from 'react';
// import ModalVideo from 'react-modal-video';
// import 'react-modal-video/scss/modal-video.scss';
// // import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination"
// import SwiperCore, { Pagination, Autoplay, A11y, EffectFade, EffectCards } from 'swiper';
// import { Carousel } from 'react-bootstrap';
// import HomeHeroSingleSlide from '../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide';

// // install Swiper modules
// SwiperCore.use([Pagination, Autoplay, A11y, EffectFade, EffectCards]);


// const HeroSection = () => {
//    const [isOpen, setOpen] = useState(false);

//    return (
//       <>

//          {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen}
//             videoId="o4GuSJYSzrY" onClose={() => setOpen(false)} /> */}

//          <section className="tp-slider-area fix">
//             <div className="tp-slider-active home_hero_slide swiper-container common-dots">


//                <Carousel interval={3000} fade={true} indicators={true}>
//                   <Carousel.Item>
//                      <HomeHeroSingleSlide setOpen={setOpen}/>
//                   </Carousel.Item>

//                   <Carousel.Item>
//                      <HomeHeroSingleSlide setOpen={setOpen}/>
//                   </Carousel.Item>

//                   <Carousel.Item>
//                      <HomeHeroSingleSlide setOpen={setOpen}/>
//                   </Carousel.Item>

//                </Carousel>

//                <div className="swiper-paginations slide-dots"></div>
//             </div>
//          </section>
//       </>
//    );
// };

// export default HeroSection;




import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import HomeHeroSingleSlide from '../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide';

const images = [
  "/assets/img/slider/slider-img-1.jpg",
  "/assets/img/slider/slider-img-2.jpg",
  "/assets/img/slider/slider-img-3.jpg"
];

const HeroSection = () => {
  const [isOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <section className="tp-slider-area fix">
      <div className="tp-slider-active home_hero_slide swiper-container common-dots">
        <Carousel
          interval={3000}
          fade={true}
          indicators={true}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <HomeHeroSingleSlide setOpen={setOpen} image={image} />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="swiper-paginations slide-dots"></div>
      </div>
    </section>
  );
};

export default HeroSection;
