import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Importing the icon

const areas = [
    "Manchester",
    "West Yorkshire",
    "Leeds",
    "Bradford",
    "Wakefield",
    "Huddersfield",
    "Halifax",
    "South Yorkshire",
    "Sheffield",
    "Doncaster",
    "Rotherham",
    "Barnsley",
    "North Yorkshire",
    "York",
    "Harrogate",
    "Scarborough",
    "Ripon",
    "Northallerton",
    "East Riding of Yorkshire",
    "Kingston upon Hull (Hull)",
    "Bridlington",
    "Beverley"
];

const styles = {
    container: {
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
        marginBottom:'50px',
        marginTop:'50px',
    },
    heading: {
        fontSize: '24px',
        marginTop: '20px'
    },
    description: {
        marginTop: '10px',
        marginBottom: '20px',
        fontSize: '16px',
        color: '#555'
    },
    list: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '10px',
        listStyle: 'none',
        padding: '0'
    },
    listItem: {
        fontSize: '16px',
        color: '#00b3ff',
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: '5px'
    }
};

const Areas = () => {
    return (
        <>
        {/* <Navigation/> */}
        <div style={styles.container}>
            <h2 style={styles.heading}>Areas We Cover</h2>
            <p style={styles.description}>
                You can schedule our Deep Cleaning Services on all postcodes in:
            </p>
            <ul style={styles.list}>
                {areas.map((area, index) => (
                    <li key={index} style={styles.listItem}>
                        <FaMapMarkerAlt style={styles.icon} /> {area}
                    </li>
                ))}
            </ul>
        </div>
        

        </>
    );
};

export default Areas;